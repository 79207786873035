import React, { useEffect, useState } from 'react';

const HideWhenOffScreen = ({ children }) => {
  const [visibility, setVisibiilty] = useState('visible');
  const [opacity, setOpacity] = useState('1');

  useEffect(() => {
    if (typeof window !== `undefined`) {
      calculateVisibility();
      window.addEventListener('scroll', calculateVisibility);

      return () => window.removeEventListener('scroll', calculateVisibility);
    }
  });

  const calculateVisibility = () => {
    const { innerHeight, scrollY } = window;
    const threshold = 1.4;
    const offScreen = scrollY > innerHeight * threshold;

    setVisibiilty(offScreen ? 'hidden' : 'visible');
    setOpacity(offScreen ? '0' : '1');
  };

  return (
    <div
      style={{
        visibility: visibility,
        opacity: opacity,
      }}
      className="hide-when-off-screen"
    >
      {children}
    </div>
  );
};

export default HideWhenOffScreen;
