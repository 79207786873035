import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';

const FadeUpOnLoad = ({ children, as, extraClass, delayBy }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const El = as ? as : 'div';

  return (
    <El
      className={classnames('fade-up-on-load', {
        'fade-up-on-load--loaded': isLoaded,
        [`fade-up-on-load--delay-${delayBy}`]: delayBy,
        [extraClass]: extraClass,
      })}
    >
      {children}
    </El>
  );
};

export default memo(FadeUpOnLoad);
