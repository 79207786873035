import React from 'react';

import FadeUpOnLoad from '../FadeUpOnLoad';
import FadeUpIntoView from '../FadeUpIntoView';

const AnimateFormatted = ({ on, text, skip }) => {
  const El = on === 'load' ? FadeUpOnLoad : FadeUpIntoView;
  const splitText = text.split('/');

  return (
    <>
      {splitText.map((text, i) => (
        <El
          extraClass="animate-by__el animate-by__el--line"
          key={`${text}-${i}`}
          delayBy={skip && skip + (i + 1)}
          as="span"
        >
          {text.trim()}{' '}
        </El>
      ))}
    </>
  );
};

export default AnimateFormatted;
