import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import classnames from 'classnames';

import FadeIntoView from '../FadeIntoView';

const ProjectImage = ({ image, alt, isFullBleed, text, slot }) => {
  return (
    <section
      className={classnames(
        'panel',
        'panel--inset',
        'panel--portfolio',
        'project__section',
        {
          [`project__section--in-flow`]: slot,
        }
      )}
    >
      <figure
        className={classnames('portfolio-screen', {
          'portfolio-screen--full-bleed': isFullBleed,
        })}
      >
        <div className="portfolio-screen__image">
          <FadeIntoView>
            <Img fluid={image.fluid} fadeIn={false} loading="eager" alt={alt} />
          </FadeIntoView>
        </div>
      </figure>
      {text && text.length > 1 && (
        <p className="panel--portfolio__text panel--portfolio__text--caption">
          {text}
        </p>
      )}
    </section>
  );
};

export default ProjectImage;
