export default (to) => {
  if (window) {
    const convertRGB = (colour) =>
      colour.replace('rgb(', '').replace(')', '').split(',').map(Number);

    const seconds = 1;
    const fps = 60;
    const iterations = seconds * fps;
    const timing = Math.ceil((seconds * 1000) / iterations);

    const metaTag = document.querySelector("meta[name='theme-color']");

    if (!metaTag) return null;

    const currentColour = convertRGB(metaTag.content);
    const toColour = convertRGB(to);

    const redDifference = ((currentColour[0] - toColour[0]) / iterations) * -1;
    const greenDifference =
      ((currentColour[1] - toColour[1]) / iterations) * -1;
    const blueDifference = ((currentColour[2] - toColour[2]) / iterations) * -1;

    let count = 0;

    const originalRed = currentColour[0];
    const originalGreen = currentColour[1];
    const originalBlue = currentColour[2];

    const calculate = setInterval(() => {
      count++;

      if (count === iterations) {
        clearInterval(calculate);
      }

      const thisRed = originalRed + redDifference * count;
      const thisGreen = originalGreen + greenDifference * count;
      const thisBlue = originalBlue + blueDifference * count;

      const newRGB = `rgb(${Math.floor(thisRed)}, ${Math.floor(
        thisGreen
      )}, ${Math.floor(thisBlue)})`;

      metaTag.setAttribute('content', newRGB);
    }, timing);
  }
};
