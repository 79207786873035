import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import BackgroundImage from 'gatsby-background-image';
import classnames from 'classnames';

import switchTheme from '../utils/switchTheme';

import AnimateFormatted from '../components/AnimateFormatted';
import FadeIntoView from '../components/FadeIntoView';
import FadeUpIntoView from '../components/FadeUpIntoView';
import FadeOnLoad from '../components/FadeOnLoad';
import FadeOnScroll from '../components/FadeOnScroll';
import FadeUpOnLoad from '../components/FadeUpOnLoad';
import HideWhenOffScreen from '../components/HideWhenOffScreen';
import Layout from '../components/Layout';
import ProjectGrid from '../components/ProjectGrid';
import ProjectImage from '../components/ProjectImage';
import SEO from '../components/SEO';
import LinkButton from '../components/LinkButton';

export const query = graphql`
  query ProjectPage($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      slug
      title
      formattedTitle
      client
      metaDescription
      category
      heroImage {
        fluid(maxWidth: 2882, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      heroTheme
      briefLeadParagraph {
        text: briefLeadParagraph
      }
      briefParagraph {
        text: briefParagraph
      }
      slot1Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot1ImageMobile {
        alt: description
        fluid(maxWidth: 804, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot1Video
      slot2TitleLeft
      slot2TextLeft {
        text: slot2TextLeft
      }
      slot2TitleRight
      slot2TextRight {
        text: slot2TextRight
      }
      slot3Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot3ImageFullBleed {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot3Text {
        text: slot3Text
      }
      slot4Image {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot4ImageAlignment
      slot4Text {
        text: slot4Text
      }
      slot5Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot5ImageFullBleed {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot5Text {
        text: slot5Text
      }
      slot6Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot6ImageFullBleed {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot6Text {
        text: slot6Text
      }
      slot7Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot7ImageFullBleed {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot7Text {
        text: slot7Text
      }
      slot8Image {
        alt: description
        fluid(maxWidth: 1550, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      slot8ImageFullBleed {
        alt: description
        fluid(maxWidth: 2310, quality: 95) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      footerPromo
      footerPromoTheme
    }
  }
`;

const BlogPost = ({
  data: {
    project: {
      slug,
      title,
      formattedTitle,
      client,
      metaDescription,
      category,
      heroImage,
      heroTheme,
      briefLeadParagraph = {},
      briefParagraph = {},
      slot1Image = {},
      slot1ImageMobile = {},
      slot1Video = {},
      slot2TitleLeft = {},
      slot2TextLeft = {},
      slot2TitleRight = {},
      slot2TextRight = {},
      slot3Image = {},
      slot3ImageFullBleed = {},
      slot3Text = {},
      slot4Image = {},
      slot4ImageAlignment = {},
      slot4Text = {},
      slot5Image = {},
      slot5ImageFullBleed = {},
      slot5Text = {},
      slot6Image = {},
      slot6ImageFullBleed = {},
      slot6Text = {},
      slot7Image = {},
      slot7ImageFullBleed = {},
      slot7Text = {},
      slot8Image = {},
      slot8ImageFullBleed = {},
      footerPromo,
      footerPromoTheme,
    },
  },
  pageContext,
}) => {
  const [heroScale, setHeroScale] = useState(1);
  const [settingTheme, setSettingTheme] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      calculateHeroScale();
      window.addEventListener('scroll', calculateHeroScale);
      return () => window.removeEventListener('scroll', calculateHeroScale);
    }
  });

  const calculateHeroScale = () => {
    const { innerHeight, scrollY } = window;
    const newScale = 1 + (scrollY / innerHeight) * 0.15;

    setHeroScale(newScale < 1 ? 1 : newScale);
  };

  const setHeroDominantColour = () => {
    if (settingTheme) return;

    setSettingTheme(true);

    const storageKey = 'page-themes';

    const getAverageRGB = (imgEl) => {
      var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {
          r: 0,
          g: 0,
          b: 0,
        }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = {
          r: 0,
          g: 0,
          b: 0,
        },
        count = 0;

      if (!context) {
        return defaultRGB;
      }
      height = canvas.height =
        imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
      width = canvas.width =
        imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
      context.drawImage(imgEl, 0, 0);

      try {
        data = context.getImageData(0, 0, width, height);
      } catch (e) {
        /* security error, img on diff domain */
        alert('x');
        return defaultRGB;
      }
      length = data.data.length;
      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }
      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);

      return rgb;
    };

    let storedThemes = {};

    if (localStorage.getItem(storageKey)) {
      storedThemes = JSON.parse(localStorage.getItem(storageKey));
    }

    if (storedThemes && slug in storedThemes) {
      return switchTheme(storedThemes[slug]);
    }

    (async () => {
      let blob = await fetch(`https:${heroImage.fluid.src}`).then((r) =>
        r.blob()
      );
      let dataUrl = await new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });

      // In order to use the data url, we need to create an img and wait for it to load
      let img = document.createElement('img');
      img.classList = 'project__image-clone';
      document.body.appendChild(img);
      img.onload = (e) => {
        var rgb = getAverageRGB(img);
        const rgbText = 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';

        storedThemes[slug] = rgbText;
        localStorage.setItem(storageKey, JSON.stringify(storedThemes));

        switchTheme(rgbText);
      };
      img.src = dataUrl;
    })();
  };

  let videoID = '';
  switch (slot1Video) {
    case 'Stoosh':
      videoID = 'in-our-skin';
      break;
    case 'Red Central':
      videoID = 'the-game-changers';
      break;
    case 'Brand Culture Studio':
      videoID = 'brand-culture-studio';
      break;
    case 'IMG Licensing':
      videoID = 'img-licensing';
      break;
    case 'Oceantee':
      videoID = 'oceantee';
      break;
    case 'nShift':
      videoID = 'nshift';
      break;
    case 'Lyxor':
      videoID = 'lyxor';
      break;
    case 'ToyBoxX':
      videoID = 'toyboxx';
      break;
    case 'Intent':
      videoID = 'intent';
      break;
    case 'Tide':
      videoID = 'tide';
      break;
    case 'Polkadot Sub0':
      videoID = 'polkadot';
      break;
    case 'Sidekick Health':
      videoID = 'sidekick';
      break;
    case 'Warwick Acoustics':
      videoID = 'warwick-acoustics';
      break;
    default:
      break;
  }

  const returnStaticVideo = (name, extension) =>
    `https://wreel.agency/video/${name}.${extension}`;

  return (
    <Layout
      headerTheme={heroTheme}
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
      mainClass="project"
    >
      <SEO title={`${client} - ${title}`} description={metaDescription} />
      <HideWhenOffScreen>
        <BackgroundImage
          Tag="section"
          fluid={heroImage.fluid}
          className="panel panel--header hero-panel"
          style={{
            position: 'fixed',
            transform: `scale(${heroScale})`,
          }}
          onLoad={() => setHeroDominantColour()}
          onStartLoad={({ wasCached }) => {
            if (wasCached) {
              setTimeout(() => {
                setHeroDominantColour();
              }, 500);
            }
          }}
        >
          <FadeOnScroll slow>
            <div
              className={classnames('hero-panel__inner', {
                'hero-panel__inner--dark': heroTheme === 'dark',
              })}
            >
              <FadeUpOnLoad as="h2" extraClass="hero-panel__client">
                {client}
              </FadeUpOnLoad>
              <h1 className="hero-panel__title">
                <AnimateFormatted on="load" text={formattedTitle} />
              </h1>
              <FadeUpOnLoad as="p" extraClass="hero-panel__category">
                {category}
              </FadeUpOnLoad>
            </div>
          </FadeOnScroll>
          <FadeOnLoad>
            <a
              href="#main"
              className={classnames('scroll-to-indicator', {
                [`scroll-to-indicator--${heroTheme}`]: heroTheme,
              })}
            >
              &#9660;
            </a>
          </FadeOnLoad>
        </BackgroundImage>
      </HideWhenOffScreen>
      <div className="panel panel--header hero-panel--spacer">&nbsp;</div>
      <section
        className="panel panel--inset panel--portfolio brief-split-panel project__section project__section--light"
        id="main"
      >
        <div className="brief-split-panel__inner">
          <div className="portfolio-brief portfolio-brief--split">
            {briefLeadParagraph && (
              <FadeUpIntoView>
                <p className="portfolio-brief__intro">
                  {briefLeadParagraph.text}
                </p>
              </FadeUpIntoView>
            )}
            {briefParagraph && (
              <p className="portfolio-brief__text">{briefParagraph.text}</p>
            )}
          </div>
        </div>
      </section>
      <section className="panel panel--inset panel--portfolio project__section project__section--split-background">
        <figure className="portfolio-screen">
          {!slot1Video && (slot1Image || slot1ImageMobile) && (
            <div
              className={classnames('portfolio-screen__image', {
                'portfolio-screen__image--app--featured': slot1ImageMobile,
              })}
            >
              <FadeIntoView>
                <Img
                  fluid={slot1Image ? slot1Image.fluid : slot1ImageMobile.fluid}
                  fadeIn={false}
                  loading="eager"
                  alt={slot1Image ? slot1Image.alt : slot1ImageMobile.alt}
                />
              </FadeIntoView>
            </div>
          )}
          {slot1Video && (
            <FadeIntoView>
              <video
                className="portfolio-screen__video"
                width="100%"
                loop
                autoPlay
                muted
                preload="metadata"
                playsInline
                disablePictureInPicture
                poster={returnStaticVideo(videoID, 'jpg')}
              >
                <source
                  src={returnStaticVideo(videoID, 'mp4')}
                  type="video/mp4"
                />
                <source
                  src={returnStaticVideo(videoID, 'webm')}
                  type="video/webm"
                />
              </video>
            </FadeIntoView>
          )}
        </figure>
      </section>
      <section className="panel panel--inset panel--portfolio project__section project__section--dark">
        <div className="panel--portfolio__text panel--portfolio__text--half-width">
          <h3>{slot2TitleLeft}</h3>
          {slot2TextLeft && <p>{slot2TextLeft.text}</p>}
        </div>
        <div className="panel--portfolio__text panel--portfolio__text--half-width">
          <h3>{slot2TitleRight}</h3>
          {slot2TextRight && <p>{slot2TextRight.text}</p>}
        </div>
      </section>

      {(slot3Image || slot3ImageFullBleed) && (
        <ProjectImage
          image={slot3Image ? slot3Image : slot3ImageFullBleed}
          alt={slot3Image ? slot3Image.alt : slot3ImageFullBleed.alt}
          isFullBleed={slot3ImageFullBleed}
          text={slot3Text ? slot3Text.text : ''}
          slot={3}
        />
      )}

      {slot4Image && (
        <section className="panel panel--inset panel--portfolio project__section  project__section--in-flow">
          <figure
            className={classnames('portfolio-screen', {
              'portfolio-screen--offset-right':
                !slot4ImageAlignment || slot4ImageAlignment === 'right',
              'portfolio-screen--offset-left': slot4ImageAlignment === 'left',
            })}
          >
            <div className="portfolio-screen__image">
              <FadeIntoView>
                <Img
                  fluid={slot4Image.fluid}
                  fadeIn={false}
                  loading="eager"
                  alt={slot4Image.alt}
                />
              </FadeIntoView>
            </div>
          </figure>
          {slot4Text && (
            <div
              className={classnames('panel--portfolio__text', {
                'panel--portfolio__text--offset-left':
                  !slot4ImageAlignment || slot4ImageAlignment === 'right',
                'panel--portfolio__text--offset-right':
                  slot4ImageAlignment === 'left',
              })}
            >
              <p>{slot4Text.text}</p>
            </div>
          )}
        </section>
      )}

      {(slot5Image || slot5ImageFullBleed) && (
        <ProjectImage
          image={slot5Image ? slot5Image : slot5ImageFullBleed}
          alt={slot5Image ? slot5Image.alt : slot5ImageFullBleed.alt}
          isFullBleed={slot5ImageFullBleed}
          text={slot5Text ? slot5Text.text : ''}
          slot={5}
        />
      )}

      {(slot6Image || slot6ImageFullBleed) && (
        <ProjectImage
          image={slot6Image ? slot6Image : slot6ImageFullBleed}
          alt={slot6Image ? slot6Image.alt : slot6ImageFullBleed.alt}
          isFullBleed={slot6ImageFullBleed}
          text={slot6Text ? slot6Text.text : ''}
          slot={6}
        />
      )}

      {(slot7Image || slot7ImageFullBleed) && (
        <ProjectImage
          image={slot7Image ? slot7Image : slot7ImageFullBleed}
          alt={slot7Image ? slot7Image.alt : slot7ImageFullBleed.alt}
          isFullBleed={slot7ImageFullBleed}
          text={slot7Text ? slot7Text.text : ''}
          slot={7}
        />
      )}

      {(slot8Image || slot8ImageFullBleed) && (
        <ProjectImage
          image={slot8Image ? slot8Image : slot8ImageFullBleed}
          alt={slot8Image ? slot8Image.alt : slot8ImageFullBleed.alt}
          isFullBleed={slot8ImageFullBleed}
          slot={8}
        />
      )}
      <section className="panel panel--portfolio project__section  project__section--spacer"></section>
      <section className="panel promo-panel promo-panel--projects promo-panel--light">
        <div>
          <h1 className="promo-panel__title promo-panel__title--project">
            <FadeUpIntoView>More work</FadeUpIntoView>
          </h1>
          <LinkButton
            text="View all work"
            link="/work/"
            theme="outline"
            hasArrow={true}
          />
        </div>
      </section>
      <ProjectGrid
        projects={pageContext.projects}
        limited={true}
        inFlow={true}
      />
    </Layout>
  );
};

export default BlogPost;
