import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';

const FadeOnLoad = ({ children, as, extraClass }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const El = as ? as : 'div';

  return (
    <El
      className={classnames('fade-on-load', {
        'fade-on-load--loaded': isLoaded,
        [extraClass]: extraClass,
      })}
    >
      {children}
    </El>
  );
};

export default memo(FadeOnLoad);
